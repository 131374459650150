/* eslint-disable global-require */
if (require('@formatjs/intl-getcanonicallocales/should-polyfill').shouldPolyfill()) {
  require('@formatjs/intl-getcanonicallocales/polyfill');
}

if (require('@formatjs/intl-locale/should-polyfill').shouldPolyfill()) {
  require('@formatjs/intl-locale/polyfill');
}

if (require('@formatjs/intl-numberformat/should-polyfill').shouldPolyfill()) {
  require('@formatjs/intl-numberformat/polyfill');
  require('@formatjs/intl-numberformat/locale-data/en');
  require('@formatjs/intl-numberformat/locale-data/fr');
  require('@formatjs/intl-numberformat/locale-data/it');
  require('@formatjs/intl-numberformat/locale-data/zh');
  require('@formatjs/intl-numberformat/locale-data/de');
  require('@formatjs/intl-numberformat/locale-data/es');
}

if (require('@formatjs/intl-datetimeformat/should-polyfill').shouldPolyfill()) {
  require('@formatjs/intl-datetimeformat/polyfill');
  require('@formatjs/intl-datetimeformat/add-all-tz');
  require('@formatjs/intl-datetimeformat/locale-data/en');
  require('@formatjs/intl-datetimeformat/locale-data/fr');
  require('@formatjs/intl-datetimeformat/locale-data/it');
  require('@formatjs/intl-datetimeformat/locale-data/zh');
  require('@formatjs/intl-datetimeformat/locale-data/de');
  require('@formatjs/intl-datetimeformat/locale-data/es');
}

if (require('@formatjs/intl-pluralrules/should-polyfill').shouldPolyfill()) {
  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/locale-data/en');
  require('@formatjs/intl-pluralrules/locale-data/fr');
  require('@formatjs/intl-pluralrules/locale-data/it');
  require('@formatjs/intl-pluralrules/locale-data/zh');
  require('@formatjs/intl-pluralrules/locale-data/de');
  require('@formatjs/intl-pluralrules/locale-data/es');
}

if (require('@formatjs/intl-relativetimeformat/should-polyfill').shouldPolyfill()) {
  require('@formatjs/intl-relativetimeformat/polyfill');
  require('@formatjs/intl-relativetimeformat/locale-data/en');
  require('@formatjs/intl-relativetimeformat/locale-data/fr');
  require('@formatjs/intl-relativetimeformat/locale-data/it');
  require('@formatjs/intl-relativetimeformat/locale-data/zh');
  require('@formatjs/intl-relativetimeformat/locale-data/de');
  require('@formatjs/intl-relativetimeformat/locale-data/es');
}
