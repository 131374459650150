import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { AUCTIONEERS_OFFICIAL_COUNT, IMAGE_SOURCE, MODULE_EXPORT_PDF, MODULE_FUTURESALE, MODULE_IMAGE, MODULE_INDEXES, MODULE_INDICATOR, MODULE_PASTSALE, MODULE_STORE } from '../../../../../constants/rails';
import { getSettings } from '../../../../../redux/selectors/subscriptions/settings';
import { getUserContext } from '../../../../../redux/selectors/userContext';
import A18n from '../../../../common/A18n';

const Module = ({ idmodule, themes, checked, label, children, forceUncheck, value = '✓' }) => {
  const { idcustomer } = useSelector(getUserContext);
  const { requiredIdmodule } = useSelector(getSettings);
  const intl = useIntl();

  return (
    <tr>
      <td>
        {label}

        {!label && (
          <>
            {idmodule === MODULE_PASTSALE && <A18n rsx="subscriptions.list.table.module.m8" replace={[['%AUCTIONEERS_OFFICIAL_COUNT%', intl.formatNumber(AUCTIONEERS_OFFICIAL_COUNT)]]} />}
            {idmodule === MODULE_FUTURESALE && <A18n rsx="subscriptions.list.table.module.m9" />}
            {idmodule === MODULE_IMAGE && <A18n rsx="subscriptions.list.table.module.m10" />}
            {idmodule === MODULE_INDEXES && (
              <div className="ia-container">
                <img className="ia" src={`${IMAGE_SOURCE}subscription/ai-transparent.svg`} alt="Intuitive Art Market" />{' '}
                <div>
                  <A18n rsx="subscriptions.list.table.module.m11" />
                </div>
              </div>
            )}
            {idmodule === MODULE_INDICATOR && (
              <div className="ia-container">
                <img className="ia" src={`${IMAGE_SOURCE}subscription/ai-transparent.svg`} alt="Intuitive Art Market" />{' '}
                <div>
                  <A18n rsx="subscriptions.list.table.module.m12" />
                </div>
              </div>
            )}
            {idmodule === MODULE_EXPORT_PDF && <A18n rsx="subscriptions.list.table.module.m13" />}
            {idmodule === MODULE_STORE && <A18n rsx="subscriptions.list.table.module.m7" />}
          </>
        )}
        {children}
      </td>
      {!idcustomer && !requiredIdmodule && <td className="essential">{checked && <span>{typeof value === 'function' ? value() : value}</span>}</td>}
      {themes.map(theme => {
        if (!forceUncheck && (checked || theme.module.find(id => id === idmodule))) {
          return (
            <td className={theme.theme_ref} key={`${idmodule}-${theme.theme_ref}-${label || 'db'}-1`}>
              {typeof value === 'function' ? value(theme) : value}
            </td>
          );
        }

        return (
          <td className={theme.theme_ref} key={`${idmodule}-${theme.theme_ref}-${label || 'db'}-0`}>
            &nbsp;
          </td>
        );
      })}
    </tr>
  );
};
export default Module;
