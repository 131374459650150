import { createNextState } from '@reduxjs/toolkit';
import { merge, omit, get, set } from 'lodash';
import {
  UNFOLLOW_ARTIST,
  UPDATE_ARTISTS,
  ADD_LOT_FOLLOW,
  REMOVE_LOT_FOLLOW,
  ADD_CLASSIFIED_FOLLOW,
  REMOVE_CLASSIFIED_FOLLOW,
  GET_LOT_SIMILAR,
  UPDATE_FAVORITE_CLASSIFIEDS,
  UPDATE_FAVORITE_STORES,
  ADD_STORE_FOLLOW,
  REMOVE_STORE_FOLLOW,
  INCLUDE_IN_PUSH,
} from '../../actions/myartprice/datas';
import { UPDATE_ARTIST_PREFERENCES } from '../../actions/myartprice/settings';
import { SHORTCUT_SETTINGS_ARTISTS } from '../../../constants/myartprice';

function getInitialState() {
  return {
    artists: undefined,
    samples: {
      past: {
        count: 0,
      },
      future: {
        count: 0,
      },
      marketplace: {
        count: 0,
      },
    },
    favoriteLot: {
      data: [],
      loading: false,
    },
    favoriteClassified: {
      data: [],
      loading: false,
    },
    favoriteStore: {
      data: [],
      loading: false,
    },
    loading: false,
  };
}

export default function datasReducer(state = getInitialState(), { type, payload }) {
  return createNextState(state, draft => {
    switch (type) {
      case UNFOLLOW_ARTIST: {
        const artists = get(draft, 'artists') || {};
        set(draft, 'artists', omit(artists, payload.idartists));
        break;
      }

      case INCLUDE_IN_PUSH: {
        const { idartist } = payload;
        const artists = get(draft, 'artists') || {};
        set(draft, `artists.${idartist}`, { ...artists[idartist], includeInPush: true });
        break;
      }

      case UPDATE_ARTISTS: {
        const { artists, loading, error, lvl } = payload;
        merge(draft, {
          artists,
          loading,
          error,
          lvl,
        });
        break;
      }

      case UPDATE_ARTIST_PREFERENCES: {
        const { section, value, params } = payload;
        if (section === SHORTCUT_SETTINGS_ARTISTS) {
          set(draft, `artists.${params.idartist}.include_in_push`, value);
        }
        break;
      }

      case ADD_LOT_FOLLOW:
        set(draft, 'favoriteLot', { ...state.favoriteLot, data: state.favoriteLot.data.concat(payload.idlot) });
        break;
      case REMOVE_LOT_FOLLOW:
        set(draft, 'favoriteLot', { ...state.favoriteLot, data: state.favoriteLot.data.filter(idlot => payload.idlot !== idlot) });
        break;

      case UPDATE_FAVORITE_CLASSIFIEDS: {
        const { data, loading, error } = payload;
        set(draft, 'favoriteClassified', { ...state.favoriteClassified, data, loading, error });
        break;
      }
      case ADD_CLASSIFIED_FOLLOW:
        set(draft, 'favoriteClassified', { ...state.favoriteClassified, data: state.favoriteClassified.data.concat(payload.idclassified) });
        break;
      case REMOVE_CLASSIFIED_FOLLOW:
        set(draft, 'favoriteClassified', { ...state.favoriteClassified, data: state.favoriteClassified.data.filter(idclassified => payload.idclassified !== idclassified) });
        break;

      case UPDATE_FAVORITE_STORES: {
        const { data, loading, error } = payload;
        set(draft, 'favoriteStore', { ...state.favoriteStore, data, loading, error });
        break;
      }
      case ADD_STORE_FOLLOW:
        set(draft, 'favoriteStore', { ...state.favoriteStore, data: state.favoriteStore.data.concat(payload.idstore) });
        break;
      case REMOVE_STORE_FOLLOW:
        set(draft, 'favoriteStore', { ...state.favoriteStore, data: state.favoriteStore.data.filter(idstore => payload.idstore !== idstore) });
        break;

      case GET_LOT_SIMILAR:
        set(draft, 'similarLots', payload);
        break;
      default:
        return draft;
    }
  });
}
