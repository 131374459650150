import i18next from 'i18next';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedNumber, useIntl } from 'react-intl';
import { getSettings } from '../../../../../../redux/selectors/subscriptions/settings';
import { getUserContext } from '../../../../../../redux/selectors/userContext';
import OneDayRefoundInfo from './OneDayRefoundInfo';
import A18n from '../../../../../common/A18n';
import './stylesheet.scss';

const RefundInfos = ({ product, theme, disabled, refund, price, iso3 }) => {
  const components = [];
  const intl = useIntl();
  const [displayAnnotate, setDisplayAnnotate] = useState(false);
  const { lastSubscription } = useSelector(getUserContext);
  const { view, promocode, period, quantity } = useSelector(getSettings);

  if (disabled && isEmpty(components)) return null;

  if (period === 1 && theme.theme_ref === 'basics')
    components.push(
      <div key="day" onClick={() => setDisplayAnnotate(true)}>
        <OneDayRefoundInfo
          visible={displayAnnotate}
          onClick={e => {
            setDisplayAnnotate(false);
            e.stopPropagation();
          }}
        />
        <p className="annotate">
          {i18next.t('subscriptions.purchase.list.theme.physicproduct.oneday.m3')} <i className="fa text-primary fa-question-circle" />
        </p>
      </div>,
    );

  if (quantity > 1 && view !== 'upgrade')
    components.push(
      <div key="multiuser">
        <p className="multiuser">
          <A18n
            rsx="subscriptions.list.cards.card.m25"
            replace={[
              ['%price%', intl.formatNumber(price / quantity, { style: 'currency', currency: iso3.toUpperCase() })],
              ['%initialprice%', intl.formatNumber((price + refund) / quantity, { style: 'currency', currency: iso3.toUpperCase() })],
            ]}
          />
        </p>
      </div>,
    );

  if (period === 30)
    components.push(
      <p key="monthly" className="monthly">
        <A18n rsx="subscriptions.list.cards.card.m22" />
      </p>,
    );

  if (refund > 0 && view !== 'upgrade' && quantity === 1)
    components.push(
      <p key="refund">
        {/* eslint-disable-next-line react/style-prop-object */}
        <A18n
          rsx="subscriptions.list.cards.card.m4"
          replace={[
            [
              '%refund%',
              intl.formatNumber(refund, {
                style: 'currency',
                currency: iso3.toUpperCase(),
              }),
            ],
          ]}
        />
        (
        <span className="strike">
          {/* eslint-disable-next-line react/style-prop-object */}
          <FormattedNumber style="currency" value={refund + price} currency={iso3.toUpperCase()} />
        </span>
        )
      </p>,
    );

  if (refund === 0 && promocode)
    components.push(
      <p key="nopromo">
        <A18n rsx="subscriptions.list.cards.card.m21" replace={[['%promocode%', promocode]]} />
      </p>,
    );

  if (product && product.nbdays - product.original_shortened_nbdays > 0) components.push(<p key="days">+{product.nbdays - product.original_shortened_nbdays} jours offerts</p>);

  if (product && view === 'upgrade')
    components.push(
      <p key="upgrade" className="upgrade">
        {lastSubscription && new Date(lastSubscription.dates.dtExpire) >= new Date() && <A18n rsx="subscriptions.list.cards.card.m14" replace={[['%dt%', intl.formatDate(lastSubscription.dates.dtExpire)]]} />}
      </p>,
    );

  if (isEmpty(components)) return null;

  return <div className="subscriptions-list-cards-subscription-refund-infos">{components.map(component => component)}</div>;
};

export default RefundInfos;
