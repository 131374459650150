/* eslint-disable global-require */
module.exports = [
  ...require('./RecommendationsModale/i18n'),
  'components.myartprice.followartist.m2',
  'components.myartprice.followartist.m3',
  'components.myartprice.followartist.m5',
  'components.myartprice.followartist.m6',
  'components.myartprice.followartist.m7',
  'components.myartprice.followartist.m8',
  'components.myartprice.followartist.m9',
  'components.myartprice.followartist.m10',
];
