/* eslint-disable global-require */
module.exports = [
  'subscriptions.list.cards.card.details.data.m5',
  'subscriptions.list.cards.card.details.data.m15',
  'subscriptions.list.cards.modules.basics.m1',
  'subscriptions.list.cards.modules.basics.m2',
  'subscriptions.list.cards.modules.basics.m3',
  'subscriptions.list.cards.modules.basics.m4',
  'subscriptions.list.cards.modules.basics.m5',
  'subscriptions.list.cards.modules.basics.m6',
];
