import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LOGIN_OR_REGISTRATION_MODAL_OPEN_CONSTANT } from '../../../../../common/LoginOrRegistrationModal/constants';
import { ANALYTICS_SECTION_SUBSCRIPTIONS_CARD, THEME_STORE } from '../../../../../../constants/rails';
import { updateSettings } from '../../../../../../redux/actions/subscriptions/settings';
import { getSettings } from '../../../../../../redux/selectors/subscriptions/settings';
import { getUserContext } from '../../../../../../redux/selectors/userContext';
import { set } from '../../../../../../redux/actions/ui/dictionary';
import useHasWarnings from '../../../common/useHasWarnings';
import '../../../../../common/stylesheets/buttons.scss';
import useAnalytics from '../../../common/useAnalytics';
import A18n from '../../../../../common/A18n';
import './stylesheet.scss';

const Button = ({ product, theme, disabled }) => {
  const dispatch = useDispatch();
  const { lastSubscription, activeSubscription, logged } = useSelector(getUserContext);
  const { view, withoutImagePriceProduct, quantity, promocode } = useSelector(getSettings);
  const hasWarnings = useHasWarnings({ theme, quantity });
  const { addProductTocart } = useAnalytics({ section: ANALYTICS_SECTION_SUBSCRIPTIONS_CARD });

  const handleSelect = () => {
    dispatch(updateSettings({ data: { theme, selectedIdphysicproduct: product.idp, view, image: !withoutImagePriceProduct.includes(product.idp), quantity, promocode } }));
    addProductTocart({ category: theme.theme, product });

    if (logged) {
      if (hasWarnings() || withoutImagePriceProduct.includes(product.idp) || theme.idtheme === THEME_STORE) {
        dispatch(updateSettings({ data: { displayWarningsModale: true } }));
      } else {
        window.location = `/payments/informations?image=${!withoutImagePriceProduct.includes(product.idp)}&idphysicproducts[${product.idp}]=${quantity}&type=${view}&promocode=${promocode}`;
      }
    } else if (withoutImagePriceProduct.includes(product.idp) || theme.idtheme === THEME_STORE) {
      dispatch(updateSettings({ data: { displayWarningsModale: true } }));
    } else {
      dispatch(set(LOGIN_OR_REGISTRATION_MODAL_OPEN_CONSTANT, true));
    }
  };

  if (disabled) {
    return null;
  }
  return (
    <button type="button" onClick={handleSelect} className="subscriptions-list-cards-subscription-button sln-price artp-btn">
      {view !== 'upgrade' && (
        <>
          {activeSubscription && (
            <>
              {theme.theme === lastSubscription.theme && <A18n rsx="subscriptions.list.cards.card.m9" />}
              {theme.theme !== lastSubscription.theme && <A18n rsx="subscriptions.list.cards.card.m15" />}
            </>
          )}
          {!activeSubscription && <A18n rsx="subscriptions.list.cards.card.m10" />}
        </>
      )}
      {view === 'upgrade' && <A18n rsx="subscriptions.list.cards.card.m11" />}
    </button>
  );
};

export default Button;
