import { useIntl } from 'react-intl';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ANALYTICS_CLICK_OPERATION_TO_ESTIMATE_HOME, LOTS_OFFICIAL_COUNT } from '../../../../constants/rails';
import { getPhysicproducts, retrievePhysicproducts } from '../../../../redux/slices/estimates';
import { getSettings } from '../../../../redux/selectors/subscriptions/settings';
import { getCurrency } from '../../../../redux/selectors/preferences';
import { aapiBeacon } from '../../../../services/analytics/aapi';
import { CURRENCIES } from '../../../../constants/preferences';
import { useViewport } from '../../../../hooks/useViewport';
import '../../../common/stylesheets/buttons.scss';
import A18n from '../../../common/A18n';
import './stylesheet.scss';

const DEFAULT_PRICE = 72;

const Estimate = () => {
  const intl = useIntl();
  const { from } = useSelector(getSettings);
  const { viewportWidth } = useViewport();
  const dispatch = useDispatch();
  const physicproducts = useSelector(getPhysicproducts);
  const [price, setPrice] = useState(DEFAULT_PRICE);
  const currency = useSelector(getCurrency);

  useEffect(() => {
    dispatch(retrievePhysicproducts({}));
  }, []);

  useEffect(() => {
    try {
      if (physicproducts) setPrice(physicproducts.filter(p => p.nbUnits === 1)[0].prices.eur);
    } catch {
      setPrice(DEFAULT_PRICE);
    }
  }, [physicproducts]);

  return (
    <div className="subscriptions-list-estimate">
      <div className="container">
        <h2>
          <A18n rsx="subscriptions.list.m5" />
        </h2>
        <p>
          <A18n
            rsx="subscriptions.list.m6"
            trustHtml
            replace={[['%price%', intl.formatNumber(price || 71, { style: 'currency', currency: CURRENCIES[currency].iso3ForIntl, minimumFractionDigits: 0, maximumFractionDigits: 0 })]]}
          />
        </p>
        {viewportWidth >= 768 && (
          <p>
            <A18n rsx="subscriptions.list.m7" replace={[['%LOTS_OFFICIAL_COUNT%', intl.formatNumber(LOTS_OFFICIAL_COUNT)]]} />
          </p>
        )}
        <button
          type="button"
          className="artp-btn"
          onClick={() => {
            aapiBeacon('click', {
              from,
              op: ANALYTICS_CLICK_OPERATION_TO_ESTIMATE_HOME,
            });

            window.location = '/estimate';
          }}
        >
          <A18n rsx="subscriptions.list.m8" />
        </button>
      </div>
    </div>
  );
};
export default Estimate;
