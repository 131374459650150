import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedNumber } from 'react-intl';
import { getSettings } from '../../../../../redux/selectors/subscriptions/settings';
import { getUserContext } from '../../../../../redux/selectors/userContext';
import MembersModules from '../modules/Member';
import A18n from '../../../../common/A18n';
import { FREE_FAVORITE_ARTISTS_COUNT } from '../../../../../constants/rails';

const Members = ({ iso3 }) => {
  const { idcustomer } = useSelector(getUserContext);
  const { requiredIdmodule } = useSelector(getSettings);

  if (idcustomer || requiredIdmodule || !iso3) return null;

  return (
    <div className="card-container">
      <div className="card essential">
        <div className="subscriptions-list-cards-subscription-header">
          <h2>
            <A18n rsx="subscriptions.list.cards.m1" />
          </h2>
          <p>
            <A18n rsx="subscriptions.list.cards.m12" />
          </p>
        </div>
        <div className="infos">
          <div className="subscriptions-list-cards-subscription-price">
            {/* eslint-disable-next-line react/style-prop-object */}
            <FormattedNumber style="currency" value={0} currency={iso3.toUpperCase()} />
            <span className="duration">
              <A18n rsx="subscriptions.list.cards.m3" />
            </span>
          </div>

          <button
            type="button"
            className="subscriptions-list-cards-subscription-button artp-btn"
            onClick={() => {
              window.location = '/account/registration';
            }}
          >
            <A18n rsx="subscriptions.list.cards.m4" />
          </button>
        </div>
        <div className="modules">
          <MembersModules nbFavorite={FREE_FAVORITE_ARTISTS_COUNT} />
        </div>
      </div>
    </div>
  );
};

export default Members;
