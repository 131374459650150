import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateCurrencyPreference } from '../../../redux/actions/preferences';
import { getAllShouldDisplay, getArtistName, getArtistURL } from '../../../redux/selectors/indexes/artist';
import { getCurrency } from '../../../redux/selectors/preferences';
import { CURRENCIES } from '../../../constants/preferences';
import A18n from '../../common/A18n';
import './stylesheet.scss';
import { IMAGE_SOURCE } from '../../../constants/rails';

const Header = () => {
  const dispatch = useDispatch();
  const artistName = useSelector(getArtistName);
  const artistURL = useSelector(getArtistURL);
  const currency = useSelector(getCurrency);
  const shouldDisplay = useSelector(getAllShouldDisplay);

  const changeCurrency = e => {
    dispatch(updateCurrencyPreference({ currency: e.target.value }));
  };

  return (
    <div className="indexes-header">
      <div className="background">
        <div className="bg-left" />
        <div className="bg-right" />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <div className="indexes-breadcrumb">
              <a href="/">
                <A18n rsx="indexes.header.m1" />
              </a>{' '}
              /{' '}
              <a href="/search">
                <A18n rsx="indexes.header.m2" />
              </a>{' '}
              / <a href={artistURL}>{artistName}</a> / <A18n rsx="indexes.header.m3" />
            </div>

            <h1>
              {artistName} - <A18n rsx="indexes.header.m3" />
              <img
                src={`${IMAGE_SOURCE}subscription/ai-white.svg`}
                alt="Intuitive Art Market"
                style={{
                  height: 36,
                  marginLeft: 15,
                  marginTop: -16,
                }}
              />
            </h1>

            {shouldDisplay?.hasIndexesSubscription && (
              <div className="actions">
                <div className="infos">
                  <a href="/indexes/legals" target="_blank">
                    <A18n rsx="indexes.header.m4" />
                  </a>
                  <span>-</span>
                  <a href="/indexes/howto" target="_blank">
                    <A18n rsx="indexes.header.m5" />
                  </a>
                </div>
                <div style={{ flex: 1 }} />
                <div className="btn-group">
                  <button type="button" className={`btn btn-default${CURRENCIES.eur.iso3 === currency ? ' active' : ''}`} value={CURRENCIES.eur.iso3} onClick={e => changeCurrency(e)}>
                    {CURRENCIES.eur.iso1}
                  </button>
                  <button type="button" className={`btn btn-default${CURRENCIES.usd.iso3 === currency ? ' active' : ''}`} value={CURRENCIES.usd.iso3} onClick={e => changeCurrency(e)}>
                    {CURRENCIES.usd.iso1}
                  </button>
                  <button type="button" className={`btn btn-default${CURRENCIES.gbp.iso3 === currency ? ' active' : ''}`} value={CURRENCIES.gbp.iso3} onClick={e => changeCurrency(e)}>
                    {CURRENCIES.gbp.iso1}
                  </button>
                  <button type="button" className={`btn btn-default${CURRENCIES.cny.iso3 === currency ? ' active' : ''}`} value={CURRENCIES.cny.iso3} onClick={e => changeCurrency(e)}>
                    {CURRENCIES.cny.iso1}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
