/* eslint-disable global-require */
module.exports = [
  'subscriptions.list.cards.modules.premium.m1',
  'subscriptions.list.cards.modules.premium.m2',
  'subscriptions.list.cards.modules.premium.m3',
  'subscriptions.list.cards.modules.premium.m4',
  'subscriptions.list.cards.modules.premium.m5',
  'subscriptions.list.cards.modules.premium.m6',
  'subscriptions.list.cards.modules.premium.m7',
  'subscriptions.list.cards.modules.premium.m8',
  'subscriptions.list.cards.modules.premium.m9',
  'subscriptions.list.cards.modules.premium.m10',
  'subscriptions.list.cards.modules.premium.m11',
  'subscriptions.list.cards.modules.premium.m12',
  'subscriptions.list.cards.modules.premium.m13',
  'subscriptions.list.cards.modules.premium.m14',
  'subscriptions.list.cards.modules.premium.m15',
  'subscriptions.list.cards.modules.premium.m16',
  'subscriptions.list.cards.modules.premium.m17',
  'subscriptions.list.cards.modules.premium.m18',
];
