import React from 'react';
import { useSelector } from 'react-redux';
import { getCart } from '../../../../redux/slices/payments';
import { IMAGE_SOURCE } from '../../../../constants/rails';
import A18n from '../../../common/A18n';
import './stylesheet.scss';

const AvailablePayments = () => {
  const cart = useSelector(getCart);

  return (
    <div className="payment-common-available-payments">
      <h4>
        <A18n rsx="payments.common.availlablepaymennts.m1" />
      </h4>
      <div className="cards">
        <img src={`${IMAGE_SOURCE}cards/visa.svg`} alt="visa" />
        <img src={`${IMAGE_SOURCE}cards/mastercard.svg`} alt="mastercard" />
        <img src={`${IMAGE_SOURCE}cards/americanexpress.svg`} alt="americanexpress" />
        {cart?.main_products?.[0]?.product?.duration !== 1 && <img src={`${IMAGE_SOURCE}cards/paypal.svg`} alt="paypal" />}
        <img src={`${IMAGE_SOURCE}cards/discover.svg`} alt="discover" />
      </div>
      {!cart?.main_products?.[0]?.product?.isRecurrentSubscription && (
        <p>
          <A18n rsx="payments.common.availlablepaymennts.m3" />
        </p>
      )}
    </div>
  );
};
export default AvailablePayments;
