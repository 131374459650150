import React from 'react';
import { IMAGE_SOURCE, THEME_PREMIUM, THEME_PROFESSIONAL } from '../../../../../../constants/rails';
import './stylesheet.scss';

const Ia = ({ theme }) => {
  if ([THEME_PREMIUM, THEME_PROFESSIONAL].includes(theme.idtheme))
    return (
      <div className="subscriptions-list-cards-subscription-ia">
        <img src={`${IMAGE_SOURCE}subscription/ai.svg`} alt="IA" />
      </div>
    );

  return null;
};

export default Ia;
