import React from 'react';
import A18n from '../../../../../common/A18n';
import './stylesheet.scss';

const Members = ({ withProfessional, nbFavorite }) => (
  <ul className="subscriptions-list-cards-modules-members">
    <li>
      {nbFavorite && (
        <span>
          {nbFavorite} <A18n rsx="subscriptions.list.cards.m5" />
        </span>
      )}
      {!nbFavorite && <A18n rsx="subscriptions.list.cards.m13" trustHtml />}
    </li>
    <li>
      <A18n rsx="subscriptions.list.cards.m6" />
    </li>
    <li>
      <A18n rsx="subscriptions.list.cards.m11" />
    </li>
    <li>
      {!withProfessional && <A18n rsx="subscriptions.list.cards.card.details.data.m4" />}
      {withProfessional && <A18n rsx="subscriptions.list.cards.modules.member.m1" trustHtml />}
    </li>
  </ul>
);

export default Members;
