import i18next from 'i18next';
import { parseJSON } from 'date-fns/parseJSON';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { getStore } from '../../../../redux/selectors/dbEntities';
import { getLang } from '../../../../redux/selectors/preferences';
import A18n from '../../../common/A18n';
import Contact from './Contact';
import Message from './Message';
import Follow from '../Follow';
import StoreFollow from '../../stores/Follow';
import Price from './Price';
import Legals from './Legals';

const Actions = ({ classified }) => {
  const [openModalLegals, setOpenModalLegals] = useState(false);
  const [since, setSince] = useState(false);
  const lang = useSelector(getLang);
  const store = useSelector(state => getStore(state, { id: classified?.idstore }));

  useEffect(() => {
    if (store) {
      setSince(parseJSON(store.dtcreated).toLocaleDateString(lang, { year: 'numeric', month: 'long' }));
    }
  }, [store]);

  return (
    <div className="marketplace-classified-actions">
      <Legals open={openModalLegals} setOpen={setOpenModalLegals} />
      <div className="title">
        <span>{`${i18next.t('marketplace.fineart._show.html.m25')}${classified.id}`}</span>
        <Follow idclassified={classified.id} noLabel />
      </div>
      <Price classified={classified} />
      {store?.customSitename && (
        <div style={{ marginBottom: 15 }}>
          <div style={{ fontWeight: 600, textTransform: 'uppercase', fontSize: 16, marginBottom: 5 }}>
            <A18n rsx="marketplace.classified.actions.m1" replace={[['%professionaltype%', store.professionaltype]]} />
          </div>
          <div style={{ fontWeight: 600, fontSize: 16, marginBottom: 5 }}>
            <a href={store.storeUrl}>{store.sitename}</a> <StoreFollow idstore={store.id} noLabel />
          </div>
          <div>
            <A18n rsx="marketplace.free.show.contact.m3" replace={[['%date%', since]]} />
          </div>
        </div>
      )}
      <Message classified={classified} setOpenModalLegals={setOpenModalLegals} />
      <Contact store={store} />
    </div>
  );
};

export default Actions;
