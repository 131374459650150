import { differenceInHours } from 'date-fns/differenceInHours';
import { Button } from 'reactstrap';
import i18next from 'i18next';
import React from 'react';
import AddToCalendar from '../../common/AddToCalendarHoc';
import { formatFns } from '../../../utils/dates/format';
import './stylesheet.scss';
import { aapiBeacon } from '../../../services/analytics/aapi';
import { ANALYTICS_FROM_SALE, ANALYTICS_CLICK_OPERATION_ADD_TO_CALENDAR } from '../../../constants/rails';

const PopupWrapper = ({ children, isOpen, onRequestClose }) => {
  if (!isOpen) return null;

  return (
    <div className="popup-wrapper">
      <div className="links">{children}</div>
      <div className="close-popup-wrapper" onClick={e => onRequestClose(e)}>
        {i18next.t('search.bars.common.closingdropdown.m1')}
      </div>
    </div>
  );
};

const AddToCalendarDropdown = AddToCalendar(Button, PopupWrapper, {
  onDropdownOpen: () => aapiBeacon('click', { from: ANALYTICS_FROM_SALE, op: ANALYTICS_CLICK_OPERATION_ADD_TO_CALENDAR }),
});

const Add = ({ event, displayButtonLabel = false, className }) => {
  const ends = new Date(event.endDatetime);
  const starts = new Date(event.startDatetime);
  const formatedEvent = { ...event, ...{ startDatetime: formatFns(starts, "yyyyMMdd'T'HHmmss"), endDatetime: formatFns(ends, "yyyyMMdd'T'HHmmss"), duration: differenceInHours(ends, starts) } };

  return (
    <AddToCalendarDropdown
      className={`sales-add-to-calendar ${className}`}
      event={formatedEvent}
      buttonText={
        <span>
          <i className="fa fa-calendar-plus-o" />
          {displayButtonLabel && i18next.t('sales.addtocalendar.m1')}
        </span>
      }
    />
  );
};

export default Add;
