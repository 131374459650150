import React, { Component } from 'react';
import { oneOfType, string, number, func, object, bool } from 'prop-types';
import { connect } from 'react-redux';
import { get, isEqual } from 'lodash';
import { Container, Row, Col } from 'reactstrap';
import i18next from 'i18next';
import { Link, useParams } from 'react-router-dom';
import { getLang } from '../../../redux/selectors/preferences';
import { areCountryLabelsLoading as areCountryLabelsLoadingSelector, getCountryLabel, getSelectedEvent, isSelectedEventLoading, getWPData, isWPDataLoading } from '../../../redux/selectors/events';
import { fetchCountryLabelsAction, fetchSelectedEventAction, updateSelectedEventAction, fetchWordpressDataAction } from '../../../redux/actions/events';
import SmallSection from './SmallSection';
import { formatFns } from '../../../utils/dates/format';
import { mergeTwoDatesToString } from './static';
import { noFalsy } from '../../../utils/common/filters';
import Spinner from '../../common/spinners/Spinner';
import Gtm from '../../gtm/DataLayer';
import './stylesheet.scss';

const processContent = (content = '') => {
  let correctedUrlContent = content.replace(/http:\/\/wpartprice\/events\/wp-content\/uploads\//gi, 'https://imgpublic.artprice.com/img/wp/');
  const match = correctedUrlContent.match(/<img\s?.*?\s?alt="banner"\s?.*?\s?\/>/gi);

  // Traitement des GTM social networks
  const socialNetworksRegExps = [/<a href="(https:\/\/www\.facebook\.com\/[\w/_-]+)"/, /<a href="(https:\/\/twitter\.com\/[\w/-]+)"/, /<a href="(https:\/\/www.youtube\.com\/[\w-.&-?&]+)"/];
  socialNetworksRegExps.forEach(socialNetworksRegExp => {
    const linkUrls = correctedUrlContent.match(socialNetworksRegExp);
    if (linkUrls) {
      correctedUrlContent = correctedUrlContent.replace(`<a href="${linkUrls[1]}`, `<a target="social"  href="${linkUrls[1]}"`);
    }
  });

  // Traitement des GTM simple links
  [...correctedUrlContent.matchAll(/<a href="([\w:/_.&-?&]+)"/g)].forEach(linkUrls => {
    correctedUrlContent = correctedUrlContent.replace(`<a href="${linkUrls[1]}`, `<a target="out" href="${linkUrls[1]}"`);
  });

  if (!match || !match[0]) return { finalContent: correctedUrlContent };
  const img = match[0];
  const src = img.match(/src="(.*?)"/i)[1];
  correctedUrlContent = correctedUrlContent.replace(img, '');

  return { src, finalContent: correctedUrlContent };
};

class EventShow extends Component {
  componentDidMount() {
    const { selectEvent, id } = this.props;
    selectEvent({ id });
  }

  componentDidUpdate(prevProps) {
    const { fetchCountryLabels, fetchWPData, event = {} } = this.props;
    const { slug, idcountry } = event;
    if (idcountry && !isEqual(get(prevProps, 'event.idcountry'), idcountry)) {
      fetchCountryLabels(event.idcountry);
    }
    if (slug && !isEqual(get(prevProps, 'event.slug'), slug)) {
      fetchWPData();
    }
  }

  render() {
    const {
      event,
      wp,
      country,

      loading,
      isWPLoading,
      areCountryLabelsLoading,
    } = this.props;

    // eslint-disable-next-line
    if (loading) return <Spinner />;
    if (!event) return null;

    const {
      title,
      url,
      dt_event_start: dtEventStart,
      dt_event_expire: dtEventExpire,
      dt_private_view: dtPrivateView,

      city,
      postcode,
      state,
      address_line1: line1,
      address_line2: line2,
      address_line3: line3,
      metas,
    } = event;

    const { prices } = JSON.parse(metas || '{}');
    const { schedules } = JSON.parse(metas || '{}');
    const { unparsed_content: contentHtml } = wp;
    const { src, finalContent } = processContent(contentHtml);
    const place = areCountryLabelsLoading ? <Spinner color="primary" /> : [line1, postcode, city, state, country].filter(noFalsy).join(', ');

    return (
      <Container className="event-details">
        <Gtm />
        <div className="breadcrumb">
          <li>
            <a href="/">Accueil</a>
          </li>
          <li>
            <Link to="/events/">Evenements</Link>
          </li>
          <li className="active">{title}</li>
        </div>

        <Row className="event-banner">
          <Col sm={2} className="go-back">
            <Link to={-1}>
              <i className="fa fa-arrow-circle-left" title="Go back" />
            </Link>
          </Col>
          <Col sm={8} className="img-container text-center">
            {isWPLoading ? (
              <Spinner color="primary" />
            ) : (
              src && (
                <a href={url} target="_blank" rel="noopener noreferrer">
                  <img src={src} alt="main" />
                </a>
              )
            )}
          </Col>
        </Row>

        <Col sm={12} className="pad pad-b-15">
          <h1 className="text-center">
            <a href={url} target="_blank" rel="noopener noreferrer">
              {title}
            </a>
          </h1>
        </Col>

        <div className="event-informations pad pad-b-40">
          {/* Forced to put bootstrap 3 class */}
          <div>
            <SmallSection title={i18next.t('events.list.filters.dates.title')} value={mergeTwoDatesToString(dtEventStart, dtEventExpire, { ignoreTZ: true })} />
            <SmallSection title={i18next.t('events.show.section.varnishing')} value={dtPrivateView ? formatFns(dtPrivateView, 'PP', { ignoreTZ: true }) : ''} condition={!!dtPrivateView} />
            <SmallSection title={i18next.t('events.show.section.ticketprice')} value={prices} condition={prices !== '' && prices !== '<p></p>'} isHtml />
          </div>

          <div>
            <SmallSection title={i18next.t('events.show.section.place')} value={`${line2 || ''} ${line3 || ''}`.trim()} />
            <SmallSection title={i18next.t('events.show.section.address')} value={areCountryLabelsLoading ? <Spinner /> : place} />
            <SmallSection title={i18next.t('events.show.section.schedules')} value={schedules} condition={schedules !== '' && schedules !== '<p></p>'} isHtml />
          </div>
        </div>

        <Row className="event-wordpress">
          <Col sm={8} className="col-sm-offset-2 text-center">
            {/* eslint-disable-next-line react/no-danger */}
            {isWPLoading ? <Spinner style={{ width: '3rem', height: '3rem' }} color="primary" /> : wp && <span className="event-content pad pad-b-15" dangerouslySetInnerHTML={{ __html: finalContent }} />}
          </Col>
        </Row>
      </Container>
    );
  }
}

EventShow.defaultProps = {
  id: undefined,
  event: {},
  wp: {},
  country: '',

  loading: false,
  isWPLoading: false,
  areCountryLabelsLoading: false,
};

EventShow.propTypes = {
  id: oneOfType([string, number]),

  // eslint-disable-next-line
  event: object,
  // eslint-disable-next-line
  wp: object,
  country: string,

  loading: bool,
  isWPLoading: bool,
  areCountryLabelsLoading: bool,

  selectEvent: func.isRequired,
  fetchCountryLabels: func.isRequired,
  fetchWPData: func.isRequired,
};

function mapStateToProps(state) {
  const lang = getLang(state) || 'en';
  const event = getSelectedEvent(state) || {};
  const { idcountry } = event;
  const wp = getWPData(state) || {};
  return {
    lang,
    event,
    wp,
    country: getCountryLabel(state, { idcountry }),

    loading: isSelectedEventLoading(state),
    isWPLoading: isWPDataLoading(state),
    areCountryLabelsLoading: areCountryLabelsLoadingSelector(state, { idcountry }),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    selectEvent: ({ id }) => {
      dispatch(updateSelectedEventAction({ id }));
      dispatch(fetchSelectedEventAction());
    },
    fetchWPData: () => dispatch(fetchWordpressDataAction()),
    fetchCountryLabels: idcountry => dispatch(fetchCountryLabelsAction({ idcountry })),
  };
}

const ReduxedEventShow = connect(mapStateToProps, mapDispatchToProps)(EventShow);

const RouterWrapper = () => {
  const params = useParams();
  return <ReduxedEventShow id={params?.id} />;
};

export default RouterWrapper;
