import { isSameDay } from 'date-fns/isSameDay';
import { formatFns } from '../dates/format';

export function normalizeData(data, options) {
  if (!Array.isArray(data) || data.length < 1) {
    return [];
  }
  // filter on data with year >= minYear
  let filteredData = data;
  if (options && options.minYear) {
    filteredData = data.filter(item => parseInt(formatFns(new Date(item.date), 'yyyy'), 10) >= parseInt(options.minYear, 10));
  }
  // to base 100
  const coeff = parseFloat(100 / filteredData[0].value);
  return filteredData.map(item => ({ ...item, value: parseFloat(item.value) * coeff }));
}

export function valueAt({ values, date }) {
  const itemFound = values.find(el => isSameDay(new Date(el.date), new Date(date)));
  if (itemFound && itemFound.value) {
    return Number.parseFloat(itemFound.value.toFixed(2));
  }
  return null;
}
