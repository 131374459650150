import React from 'react';
import { THEME_PREMIUM, THEME_BASICS, THEME_BASICS_STORE, THEME_PROFESSIONAL, THEME_STORE } from '../../../../../../constants/rails';
import A18n from '../../../../../common/A18n';
import './stylesheet.scss';

const Header = ({ theme }) => (
  <div className="subscriptions-list-cards-subscription-header">
    <h2>{theme.theme}</h2>

    {theme.idtheme === THEME_STORE && (
      <p>
        <A18n rsx="subscriptions.list.cards.card.m23" />
      </p>
    )}
    {theme.idtheme === THEME_BASICS_STORE && (
      <p>
        <A18n rsx="subscriptions.list.cards.card.m28" />
      </p>
    )}
    {theme.idtheme === THEME_BASICS && (
      <p>
        <A18n rsx="subscriptions.list.cards.card.m29" />
      </p>
    )}
    {theme.idtheme === THEME_PREMIUM && (
      <p>
        <A18n rsx="subscriptions.list.cards.card.m26" />
      </p>
    )}
    {theme.idtheme === THEME_PROFESSIONAL && (
      <p>
        <A18n rsx="subscriptions.list.cards.card.m27" />
      </p>
    )}
  </div>
);

export default Header;
