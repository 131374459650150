/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React from 'react';
import classNames from 'classnames';
import { IMAGE_SOURCE } from '../../../../../../constants/rails';
import './stylesheet.scss';
import A18n from '../../../../../common/A18n';

const Premium = ({ disabled }) => (
  <ul className={classNames({ active: true, 'subscriptions-list-cards-modules-premium': true })}>
    <li className="ia">
      <A18n rsx="subscriptions.list.cards.modules.premium.m1" />
      {!disabled && <img src={`${IMAGE_SOURCE}subscription/ai-transparent.svg`} alt="Intuitive Art Market" />}
      <ul className={classNames({ visible: true })}>
        <li>
          <A18n rsx="subscriptions.list.cards.modules.premium.m2" />
        </li>
        <li>
          <A18n rsx="subscriptions.list.cards.modules.premium.m3" />
        </li>
        <li>
          <A18n rsx="subscriptions.list.cards.modules.premium.m4" />
        </li>
        <li>
          <A18n rsx="subscriptions.list.cards.modules.premium.m5" />
        </li>
        <li>
          <A18n rsx="subscriptions.list.cards.modules.premium.m6" />
        </li>
        <li>
          <A18n rsx="subscriptions.list.cards.modules.premium.m7" />
        </li>
        <li>
          <A18n rsx="subscriptions.list.cards.modules.premium.m8" />
        </li>
        <li>
          <A18n rsx="subscriptions.list.cards.modules.premium.m9" />
        </li>
        <li>
          <A18n rsx="subscriptions.list.cards.modules.premium.m10" />
        </li>
      </ul>
    </li>
    <li className="ia">
      <A18n rsx="subscriptions.list.cards.modules.premium.m11" />
      {!disabled && <img src={`${IMAGE_SOURCE}subscription/ai-transparent.svg`} alt="Intuitive Art Market" />}
      <ul className={classNames({ visible: true })}>
        <li>
          <A18n rsx="subscriptions.list.cards.modules.premium.m12" />
        </li>
      </ul>
    </li>
    <li>
      <A18n rsx="subscriptions.list.cards.modules.premium.m13" />
      <ul className={classNames({ visible: true })}>
        <li>
          <A18n rsx="subscriptions.list.cards.modules.premium.m14" />
        </li>
        <li>
          <A18n rsx="subscriptions.list.cards.modules.premium.m15" />
        </li>
      </ul>
    </li>
    <li>
      <A18n rsx="subscriptions.list.cards.modules.premium.m16" />
      <ul className={classNames({ visible: true })}>
        <li>
          <A18n rsx="subscriptions.list.cards.modules.premium.m17" />
        </li>
        <li>
          <A18n rsx="subscriptions.list.cards.modules.premium.m18" />
        </li>
      </ul>
    </li>
  </ul>
);
export default Premium;
