import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedNumber } from 'react-intl';
import { getSettings } from '../../../../../../redux/selectors/subscriptions/settings';
import A18n from '../../../../../common/A18n';
import './stylesheet.scss';

const Price = ({ refund, price, iso3, disabled }) => {
  const { view, period } = useSelector(getSettings);

  if (disabled)
    return (
      <div className="subscriptions-list-cards-subscription-price">
        <A18n rsx="subscriptions.list.cards.card.m16" />
      </div>
    );

  return (
    <div className="subscriptions-list-cards-subscription-price">
      {/* eslint-disable-next-line react/style-prop-object */}
      <FormattedNumber style="currency" value={price} currency={iso3.toUpperCase()} />

      {view === 'upgrade' && refund > 0 && (
        <span className="strike">
          {/* eslint-disable-next-line react/style-prop-object */}
          <FormattedNumber style="currency" value={refund + price} currency={iso3.toUpperCase()} />
        </span>
      )}
      {view !== 'upgrade' && (
        <span className="duration">
          {period === 1 && <A18n rsx="subscriptions.list.cards.card.m17" />}
          {period === 30 && <A18n rsx="subscriptions.list.cards.card.m18" />}
          {period === 365 && <A18n rsx="subscriptions.list.cards.card.m19" />}
          {period === 730 && <A18n rsx="subscriptions.list.cards.card.m20" />}
        </span>
      )}
    </div>
  );
};
export default Price;
