import React from 'react';
import { differenceInDays } from 'date-fns/differenceInDays';
import { isAfter } from 'date-fns/isAfter';
import { useSelector } from 'react-redux';
import { getActiveSubscription, getLastSubscription } from '../../../../../../../../redux/slices/userContext/selectors';
import A18n from '../../../../../../../common/A18n';
import { formatFns } from '../../../../../../../../utils/dates/format';
import { aapiBeacon } from '../../../../../../../../services/analytics/aapi';
import {
  ANALYTICS_FROM_HEADER_ACCOUNT,
  ANALYTICS_CLICK_OPERATION_TO_ACCOUNT_SITUATION,
  ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_PRICING,
  ANALYTICS_CLICK_OPERATION_TO_PRICING,
} from '../../../../../../../../constants/rails';
import { isCancelled, needsAction } from '../../../../../../../../utils/subscriptions/attentionNeeded';

const SubscriptionMessage = () => {
  const activeSubscription = useSelector(getActiveSubscription);
  const lastSubscription = useSelector(getLastSubscription);

  if (needsAction(lastSubscription?.attentionNeeded)) {
    return (
      <a href="/account/situation" onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HEADER_ACCOUNT, op: ANALYTICS_CLICK_OPERATION_TO_ACCOUNT_SITUATION })}>
        <A18n rsx="account.stripe.customer.protal.button.m1" />
      </a>
    );
  }
  if (isCancelled(activeSubscription?.attentionNeeded) && activeSubscription?.dates) {
    return <A18n rsx="layouts.header.m4" replace={[['%dt%', formatFns(activeSubscription.dates.dtExpire, 'PPP')]]} />;
  }

  if (lastSubscription?.nbDays === 1 && !lastSubscription?.started) {
    return <A18n rsx="layouts.header.m34" />;
  }
  if (activeSubscription?.nbDays === 1) {
    return <A18n rsx="component.header.myaccount.m1" replace={[['%dt%', formatFns(activeSubscription.dates.dtStart, 'PPPp')]]} />;
  }
  if (activeSubscription?.nbDays === 30 && activeSubscription?.dates) {
    return <A18n rsx="component.header.myaccount.m3" replace={[['%dt%', formatFns(activeSubscription.dates.dtExpire, 'PPP')]]} />;
  }
  if (lastSubscription?.dates && isAfter(new Date(lastSubscription.dates.dtExpire), new Date())) {
    return <A18n rsx="layouts.header.m4" replace={[['%dt%', formatFns(lastSubscription.dates.dtExpire, 'PPP')]]} />;
  }

  const hadStore = (lastSubscription?.modules || []).includes(13);
  return (
    <a
      href={`/subscription${hadStore ? '/store' : ''}`}
      onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HEADER_ACCOUNT, op: hadStore ? ANALYTICS_CLICK_OPERATION_TO_MARKETPLACE_PRICING : ANALYTICS_CLICK_OPERATION_TO_PRICING })}
    >
      <A18n rsx={lastSubscription && lastSubscription.nbDays > 1 && differenceInDays(new Date(), new Date(lastSubscription.dates.dtExpire)) < 30 ? 'layouts.header.m7' : 'layouts.header.m8'} />
    </a>
  );
};

export default SubscriptionMessage;
