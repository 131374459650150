import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ANALYTICS_SECTION_SUBSCRIPTIONS_CARD, DEFAULT_FAVORITE_ARTISTS_COUNT, THEME_BASICS, THEME_PROFESSIONAL } from '../../../../../../constants/rails';
import { updateSettings } from '../../../../../../redux/actions/subscriptions/settings';
import { getSettings } from '../../../../../../redux/selectors/subscriptions/settings';
import useAnalytics from '../../../common/useAnalytics';
import Premium from '../../modules/Premium';
import Basics from '../../modules/Basics';
import Store from '../../modules/Store';
import Members from '../../modules/Member';

const ModulesData = ({ theme, physicproduct, disabled }) => {
  const dispatch = useDispatch();
  const { withoutImagePriceProduct } = useSelector(getSettings);
  const { toggleImage: toggleImageAnalytics } = useAnalytics({ section: ANALYTICS_SECTION_SUBSCRIPTIONS_CARD });

  const handleImageFlip = () => {
    if (physicproduct?.idp) {
      toggleImageAnalytics({
        checked: !withoutImagePriceProduct.includes(physicproduct?.idp),
        slug: physicproduct.reference,
      });

      dispatch(
        updateSettings({
          data: {
            withoutImagePriceProduct: withoutImagePriceProduct.includes(physicproduct.idp) ? withoutImagePriceProduct.filter(id => id !== physicproduct.idp) : [...withoutImagePriceProduct, physicproduct.idp],
          },
        }),
      );
    }
  };

  return (
    <div className="modules">
      <Members withProfessional={theme.idtheme === THEME_PROFESSIONAL} nbFavorite={theme.idtheme !== THEME_BASICS ? null : DEFAULT_FAVORITE_ARTISTS_COUNT} />
      <Basics onImageFlip={handleImageFlip} withoutImage={withoutImagePriceProduct.includes(physicproduct?.idp)} />
      {theme.idtheme !== THEME_BASICS && <Premium disabled={disabled} />}
      {theme.idtheme === THEME_PROFESSIONAL && <Store className="subscriptions-list-cards-modules-store" />}
    </div>
  );
};

export default ModulesData;
