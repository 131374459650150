import React from 'react';
import A18n from '../../common/A18n';
import './stylesheet.scss';
import { aapiBeacon } from '../../../services/analytics/aapi';
import { ANALYTICS_CLICK_OPERATION_TO_CONTACT_HOME, ANALYTICS_FROM_FAQ_PANEL } from '../../../constants/rails';

const Panel = () => (
  <div className="howto-panel">
    <h2>
      <A18n rsx="howto.panel.m2" />
    </h2>
    <div className="actions">
      <a href="/contact?active=block9" className="btn btn-animated" onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_FAQ_PANEL, op: ANALYTICS_CLICK_OPERATION_TO_CONTACT_HOME })}>
        <A18n rsx="howto.panel.m1" />
      </a>
    </div>
  </div>
);

export default Panel;
