import * as Sentry from '@sentry/react';
import { SENTRY_DSN, VERSION } from './constants/rails';

if (typeof window !== 'undefined') {
  const enabled = !window.artprice?.sentry?.disabled;
  const replaySampling = window.artprice?.sentry?.replaySampling || 0.0;
  const enableReplay = replaySampling > 0.0;

  const sentryConf = {
    dsn: SENTRY_DSN,
    release: VERSION,
    environment: process.env.RAILS_ENV,
    normalizeDepth: 6,
    enabled,
    tunnel: '/tunnel',
    ignoreErrors: ['jQuery', 'Hydration', 'hydrating'],
    integrations: [Sentry.extraErrorDataIntegration()],
    transportOptions: {
      headers: {
        'Content-Type': 'text/plain;charset=UTF-8',
      },
    },
    // integrations(integrations) {
    //   const filteredIntregrations = integrations.filter(integration => integration.name !== 'Dedupe');
    //
    //   return [...filteredIntregrations, Sentry.extraErrorDataIntegration()];
    // },
  };

  if (enableReplay) {
    sentryConf.replaysOnErrorSampleRate = replaySampling;
    sentryConf.integrations.push(
      Sentry.replayIntegration({
        maskAllText: false,
      }),
    );
  }

  Sentry.init(sentryConf);
}
