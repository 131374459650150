import React from 'react';
import { FormattedNumber } from 'react-intl';
import { ARTISTS_OFFICIAL_COUNT, AUCTIONEERS_OFFICIAL_COUNT, LOTS_OFFICIAL_COUNT } from '../../../../constants/rails';
import './stylesheet.scss';
import A18n from '../../../common/A18n';

const Numbers = () => (
  <div className="subscriptions-list-numbers">
    <div className="bg bg-left" />
    <div className="bg bg-right" />
    <div className="container">
      <h2>
        <A18n rsx="subscriptions.list.numbers.m4" />
      </h2>
      <div className="numbers">
        <div>
          <div className="number number-1">
            <FormattedNumber value={ARTISTS_OFFICIAL_COUNT} />
          </div>
          <div className="label">
            <A18n rsx="subscriptions.list.numbers.m1" />
          </div>
        </div>
        <div>
          <div className="number number-2">
            <FormattedNumber value={LOTS_OFFICIAL_COUNT} />
          </div>
          <div className="label">
            <A18n rsx="subscriptions.list.numbers.m2" />
          </div>
        </div>
        <div>
          <div className="number number-3">
            <FormattedNumber value={AUCTIONEERS_OFFICIAL_COUNT} />
          </div>
          <div className="label">
            <A18n rsx="subscriptions.list.numbers.m3" />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Numbers;
