import React from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { format } from 'date-fns/format';
import { getArticles } from '../../../redux/slices/homepage';
import './stylesheet.scss';
import A18n from '../../common/A18n';
import Arrow from '../../common/Arrow';
import { ANALYTICS_CLICK_OPERATION_TO_ARTMARKETINSIGHT_ARTICLE, ANALYTICS_CLICK_OPERATION_TO_ARTMARKETINSIGHT_ARTICLES, ANALYTICS_FROM_HOMEPAGE } from '../../../constants/rails';
import { aapiBeacon } from '../../../services/analytics/aapi';
import '../../common/stylesheets/buttons.scss';

const Articles = () => {
  const articles = useSelector(getArticles);

  if (isEmpty(articles)) return null;

  return (
    <div className="container homepage-articles block">
      <h2 className="title">
        <A18n rsx="homepage.articles.m1" />
        <div className="all">
          <a href="/artmarketinsight" onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HOMEPAGE, op: ANALYTICS_CLICK_OPERATION_TO_ARTMARKETINSIGHT_ARTICLES })}>
            <A18n rsx="homepage.articles.m2" />
            <Arrow style={{ marginLeft: 5 }} />
          </a>
        </div>
      </h2>

      <div className="articles">
        {articles.map(article => (
          <div key={`ami${article.title}`} className="article">
            <div className="image">
              <img src={article.image} alt={article.title} className="radius" />
            </div>
            <div className="content">
              <div className="dt">{format(new Date(article.date), 'dd MMM yyyy')}</div>
              <div className="title">
                <strong>{article.title}</strong>
              </div>
              {/* eslint-disable-next-line react/no-danger */}
              <div className="excerpt" dangerouslySetInnerHTML={{ __html: article.description }} />
              <div className="lnk">
                <a
                  href={`/artmarketinsight/${article.slug}`}
                  className="artp-btn"
                  onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HOMEPAGE, op: ANALYTICS_CLICK_OPERATION_TO_ARTMARKETINSIGHT_ARTICLE, slug: article.slug })}
                >
                  <A18n rsx="homepage.articles.m3" />
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Articles;
