/* eslint-disable global-require */
module.exports = [
  'subscriptions.list.table.module.m7',
  'subscriptions.list.table.module.m8',
  'subscriptions.list.table.module.m9',
  'subscriptions.list.table.module.m10',
  'subscriptions.list.table.module.m11',
  'subscriptions.list.table.module.m12',
  'subscriptions.list.table.module.m13',
];
