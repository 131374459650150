import React from 'react';
import { useSelector } from 'react-redux';
import { ANALYTICS_CLICK_OPERATION_TO_MYARTPRICE_SETTINGS, ANALYTICS_FROM_HOMEPAGE, ANALYTICS_SECTION_SUBSCRIPTIONS_DATA } from '../../../../constants/rails';
import { getIdcustomer, getPushEmail } from '../../../../redux/slices/userContext/selectors';
import { aapiBeacon } from '../../../../services/analytics/aapi';
import '../../../common/stylesheets/buttons.scss';
import A18n from '../../../common/A18n';

const AlertEmail = () => {
  const pushEmail = useSelector(getPushEmail);
  const idcustomer = useSelector(getIdcustomer);

  return (
    <div className="homepage-alert-email homepage-auto-promo">
      <div className="l0">
        <A18n rsx="homepage.focusonorautopromo.alertemail.m1" />
      </div>
      <div className="l1">
        <A18n rsx="homepage.focusonorautopromo.alertemail.m2" />
      </div>
      <div className="l2">
        {!idcustomer && (
          <a
            className="artp-btn artp-btn-transparent"
            href="/account/registration-free?alert=1"
            onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HOMEPAGE, op: ANALYTICS_SECTION_SUBSCRIPTIONS_DATA, section: 'auto-promo-alert-email' })}
          >
            <A18n rsx="homepage.focusonorautopromo.alertemail.m3" />
          </a>
        )}
        {!pushEmail && idcustomer && (
          <a
            className="artp-btn artp-btn-transparent"
            href="/myartprice/settings/artists"
            onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HOMEPAGE, op: ANALYTICS_CLICK_OPERATION_TO_MYARTPRICE_SETTINGS, section: 'auto-promo-alert-email' })}
          >
            <A18n rsx="homepage.focusonorautopromo.alertemail.m4" />
          </a>
        )}
      </div>
    </div>
  );
};
export default AlertEmail;
