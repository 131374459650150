import i18next from 'i18next';
import { addYears } from 'date-fns/addYears';
import React, { memo, useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css'; // See app/assets/stylesheets/fix/_react_datepicker.scss
import '../../../../../../assets/stylesheets/fix/_react_datepicker.scss';
import { format } from 'date-fns/format';
import fr from 'date-fns/locale/fr';
import en from 'date-fns/locale/en-US';
import de from 'date-fns/locale/de';
import zh from 'date-fns/locale/zh-CN';
import it from 'date-fns/locale/it';
import es from 'date-fns/locale/es';
import './stylesheet.scss';

const LOCALES = { fr, en, de, zh, it, es };
const dateFormats = { en: 'MM/dd/yyyy', zh: 'yyyy-MM-dd', de: 'dd.MM.yyyy', default: 'dd/MM/yyyy' };
const maxAllowedDate = addYears(new Date(), 2);

/*
value is an object : { from:, to: }
 */
const Dates = ({ className, value = {}, onChange }) => {
  registerLocale(i18next.language, LOCALES[i18next.language]);
  const [internalValue, setInternalValue] = useState(value);
  useEffect(() => {
    setInternalValue(value);
  }, [value, setInternalValue]);

  return (
    <div className={`search-dates ${className ?? ''}`.trim()}>
      <div className="cell first" style={{ width: '50%' }}>
        <DatePicker
          title={dateFormats[i18next.language] ? dateFormats[i18next.language] : dateFormats.default}
          placeholderText={i18next.t('marketplace.searchbars.classifieds.bar.m10')}
          maxDate={internalValue.to && new Date(internalValue.to)}
          showYearDropdown
          fixedHeight
          dateFormat={dateFormats[i18next.language] ? dateFormats[i18next.language] : dateFormats.default}
          inline={false}
          locale={i18next.language}
          onChange={date => {
            if (date) {
              const formattedDate = date > maxAllowedDate ? format(maxAllowedDate, 'yyyy-MM-dd') : format(date, 'yyyy-MM-dd');

              setInternalValue(prevValue => ({ ...prevValue, from: formattedDate }));
              onChange({ from: formattedDate });
            }
          }}
          selected={internalValue.from && new Date(internalValue.from)}
        />
      </div>
      <div className="cell" style={{ width: '50%' }}>
        <DatePicker
          title={dateFormats[i18next.language] ? dateFormats[i18next.language] : dateFormats.default}
          placeholderText={i18next.t('marketplace.searchbars.classifieds.bar.m11')}
          minDate={internalValue.from && new Date(internalValue.from)}
          inline={false}
          showYearDropdown
          fixedHeight
          dateFormat={dateFormats[i18next.language] ? dateFormats[i18next.language] : dateFormats.default}
          locale={i18next.language}
          onChange={date => {
            if (date) {
              const formattedDate = date > maxAllowedDate ? format(maxAllowedDate, 'yyyy-MM-dd') : format(date, 'yyyy-MM-dd');

              setInternalValue(prevValue => ({ ...prevValue, to: formattedDate }));
              onChange({ to: formattedDate });
            }
          }}
          selected={internalValue.to && new Date(internalValue.to)}
        />
      </div>
    </div>
  );
};

export default memo(Dates);
