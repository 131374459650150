import { get } from 'lodash';
import { createSelector } from 'reselect';
import { getAllArtists } from '../dbEntities';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../../constants/utils';

const artistsFromState = state => get(state, 'myartprice.datas.artists', EMPTY_OBJECT);
const artistFromState = (state, { idartist }) => get(artistsFromState(state), idartist);
export const areDatasLoading = state => get(state, 'myartprice.datas.loading');
export const areClassifiedsDatasLoading = state => get(state, 'myartprice.datas.favoriteClassified.loading');
export const areStoresDatasLoading = state => get(state, 'myartprice.datas.favoriteStore.loading');

export const getArtists = createSelector(artistsFromState, getAllArtists, (dataArtistsFromFavorites, artistDatasFromDbEntities) => {
  const artists = [];
  if (dataArtistsFromFavorites) {
    Object.entries(dataArtistsFromFavorites).forEach(([id, data]) => {
      if (artistDatasFromDbEntities[id]) {
        const artist = artistDatasFromDbEntities[id];
        artists.push({
          ...artist,
          ...data,
        });
      }
    });
  }
  return artists;
});

const artistsSorted = (artists, sort, direction) => {
  artists.sort((a, b) => {
    if (a[sort] > b[sort]) {
      return direction === 'asc' ? 1 : -1;
    }

    if (a[sort] < b[sort]) {
      return direction === 'asc' ? -1 : 1;
    }

    return 0;
  });
  return artists;
};

export const getSortedArtists = (state, { sort = 'name_for_sorting', direction = 'asc' }) => {
  const artists = getArtists(state);
  return artistsSorted(artists, sort, direction);
};

export const getArtistParam = (state, { idartist, param }) => get(artistFromState(state, { idartist }), param);

export const getFavoriteLot = state => get(state, 'myartprice.datas.favoriteLot.data', EMPTY_ARRAY);

export const getIsFavoritedLot = (state, idlot) => get(state, 'myartprice.datas.favoriteLot.data', EMPTY_ARRAY).includes(idlot);

export const getFavoriteClassified = state => get(state, 'myartprice.datas.favoriteClassified.data', EMPTY_ARRAY);

export const getFavoriteStore = state => get(state, 'myartprice.datas.favoriteStore.data', EMPTY_ARRAY);

export const getFavoriteArtist = state => get(state, 'myartprice.datas.favoriteArtist.data', EMPTY_ARRAY);

export const getLotSimilar = state => get(state, 'myartprice.datas.similarLots.res', EMPTY_ARRAY);

export const getError = state => get(state, 'myartprice.datas.error');

export const getLvl = state => get(state, 'myartprice.datas.lvl');
