/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { useId } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import A18n from '../../../../../common/A18n';
import { useViewport } from '../../../../../../hooks/useViewport';
import { get } from '../../../../../../redux/selectors/ui/dictionary';
import { UNFOLD_KEY } from '../../../common/constants';
import { set } from '../../../../../../redux/actions/ui/dictionary';
import './stylesheet.scss';

const PREFIX = 'store';

const Store = ({ className }) => {
  const uuid = useId();
  const unfold = useSelector(state => get(state, { id: UNFOLD_KEY }));
  const dispatch = useDispatch();
  const { viewportWidth } = useViewport();

  const handleVisibility = idx => {
    if (viewportWidth <= 768) {
      const value = `${uuid}-${PREFIX}-${idx}`;
      dispatch(set(UNFOLD_KEY, value === unfold ? '' : value));
    }
  };

  return (
    <ul className={classNames({ active: true, [className]: true })}>
      <li onClick={() => handleVisibility(1)}>
        <A18n rsx="subscriptions.list.cards.card.details.data.m12" />
        <ul className={classNames({ visible: true })}>
          <li>
            <A18n rsx="subscriptions.list.cards.card.details.data.m13" />
          </li>
          <li>
            <A18n rsx="subscriptions.list.cards.modules.store.m1" />
          </li>
          <li>
            <A18n rsx="subscriptions.list.cards.modules.store.m2" />
          </li>
          <li>
            <A18n rsx="subscriptions.list.cards.modules.store.m3" />
          </li>
          <li>
            <A18n rsx="subscriptions.list.cards.modules.store.m4" />
          </li>
          <li>
            <A18n rsx="subscriptions.list.cards.modules.store.m5" />
          </li>
        </ul>
      </li>
    </ul>
  );
};
export default Store;
