import React, { Component } from 'react';
import { string, object, func, bool } from 'prop-types';
import { connect } from 'react-redux';
import { Card, CardBody, CardTitle, CardSubtitle } from 'reactstrap';
import { fetchCountryLabelsAction, fetchThumbnailAction } from '../../../../redux/actions/events';
import { getCountryLabel, areCountryLabelsLoading as areCountryLabelsLoadingSelector } from '../../../../redux/selectors/events';
import { formatFns } from '../../../../utils/dates/format';
import Spinner from '../../../common/spinners/Spinner';
import '../Item/stylesheet.scss';
import './stylesheet.scss';

class EventItem extends Component {
  componentDidMount() {
    const { fetchCountryLabels, fetchWPThumbnail, event } = this.props;
    const { slug, idcountry } = event;

    fetchCountryLabels(idcountry);
    fetchWPThumbnail(slug);
  }

  render() {
    const { event, country, areCountryLabelsLoading } = this.props;

    if (!event) return null;
    const { title, dt_event_start: dtEventStart, dt_event_expire: dtEventExpire } = event;

    const countryLabel = (areCountryLabelsLoading ? <Spinner /> : country) || '';

    return (
      <div className="exposition-block not-partner-item col-xs-12 marg marg-b-20">
        <Card>
          <CardBody>
            <CardTitle>
              <h2>
                <small>
                  <span>{title}</span>
                </small>
              </h2>
            </CardTitle>
            <CardSubtitle>
              <h3 className="marg marg-t-0 marg-b-0">
                <small>
                  <div className="nowrap">
                    <span>{countryLabel}</span>
                  </div>
                  <div className="nowrap">
                    <span>
                      ({formatFns(dtEventStart, 'P', { ignoreTZ: true })}
                      {' - '}
                      {formatFns(dtEventExpire, 'P', { ignoreTZ: true })})
                    </span>
                  </div>
                </small>
              </h3>
            </CardSubtitle>
          </CardBody>
        </Card>
      </div>
    );
  }
}

EventItem.defaultProps = {
  country: '',
  areCountryLabelsLoading: false,
};

EventItem.propTypes = {
  country: string,
  areCountryLabelsLoading: bool,
  // eslint-disable-next-line
  event: object.isRequired,

  fetchCountryLabels: func.isRequired,
  fetchWPThumbnail: func.isRequired,
};

function mapStateToProps(state, ownProps) {
  const { event: { idcountry } = {} } = ownProps;
  return {
    country: getCountryLabel(state, { idcountry }),
    areCountryLabelsLoading: areCountryLabelsLoadingSelector(state, { idcountry }),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCountryLabels: idcountry => dispatch(fetchCountryLabelsAction({ idcountry })),
    fetchWPThumbnail: slug => dispatch(fetchThumbnailAction({ slug })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventItem);
