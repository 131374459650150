/* eslint-disable global-require */
module.exports = [
  'subscriptions.list.table.m3',
  'subscriptions.list.table.m4',
  'subscriptions.list.table.m5',
  'subscriptions.list.table.m6',
  'subscriptions.list.table.m7',
  'subscriptions.list.table.m8',
  'subscriptions.list.table.m9',
  'subscriptions.list.table.m10',
  'subscriptions.list.table.m11',
  'subscriptions.list.table.m12',
  'subscriptions.list.table.m13',
  'subscriptions.list.table.m14',
  'subscriptions.list.table.m16',
  'subscriptions.list.table.m17',
  'subscriptions.list.table.m18',
  'subscriptions.list.table.m19',
  'subscriptions.list.table.m20',
  'subscriptions.list.table.m23',
  'subscriptions.list.table.m25',
  'subscriptions.list.table.m26',
  'subscriptions.list.table.m27',
  'subscriptions.list.table.m28',
  'subscriptions.list.table.m29',
  'subscriptions.list.table.m30',
  'subscriptions.list.table.m31',
  'subscriptions.list.table.m32',
  'subscriptions.list.table.m33',
  'subscriptions.list.table.m34',
  'subscriptions.list.table.m35',
  'subscriptions.list.table.m36',
  'subscriptions.list.table.m37',
  'subscriptions.list.table.m38',
  'subscriptions.list.table.m39',
  'subscriptions.list.table.m40',
  'subscriptions.list.table.m41',
  'subscriptions.list.table.m42',
  'subscriptions.list.table.m43',
  'subscriptions.list.table.m44',
  'subscriptions.list.table.m45',
  'subscriptions.list.table.m46',
  'subscriptions.list.table.m47',
  'subscriptions.list.table.m48',
  'subscriptions.list.table.m49',
  'subscriptions.list.table.m50',
  ...require('./Module/i18n'),
];
