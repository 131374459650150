/* eslint-disable global-require */
module.exports = [
  'subscriptions.list.cards.modules.store.m1',
  'subscriptions.list.cards.modules.store.m2',
  'subscriptions.list.cards.modules.store.m3',
  'subscriptions.list.cards.modules.store.m4',
  'subscriptions.list.cards.modules.store.m5',
  'subscriptions.list.cards.card.details.data.m12',
  'subscriptions.list.cards.card.details.data.m13',
];
