import React from 'react';
import { useSelector } from 'react-redux';
import { ANALYTICS_FROM_HOMEPAGE, ANALYTICS_SECTION_SUBSCRIPTIONS_DATA } from '../../../../constants/rails';
import { getIdcustomer, getNewsletter } from '../../../../redux/slices/userContext/selectors';
import { aapiBeacon } from '../../../../services/analytics/aapi';
import '../../../common/stylesheets/buttons.scss';
import A18n from '../../../common/A18n';

const Newsletter = () => {
  const idcustomer = useSelector(getIdcustomer);
  const newsletter = useSelector(getNewsletter);

  return (
    <div className="homepage-newsletter homepage-auto-promo">
      <div className="l0">
        <A18n rsx="homepage.focusonorautopromo.newsletter.m1" />
      </div>
      <div className="l1">
        <A18n rsx="homepage.focusonorautopromo.newsletter.m2" />
      </div>
      <div className="l2">
        {!idcustomer && (
          <a
            className="artp-btn artp-btn-transparent"
            href="/account/registration-free?news=1"
            onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HOMEPAGE, op: ANALYTICS_SECTION_SUBSCRIPTIONS_DATA, section: 'auto-promo-newsletter' })}
          >
            <A18n rsx="homepage.focusonorautopromo.newsletter.m3" />
          </a>
        )}
        {idcustomer && !newsletter && (
          <a className="artp-btn artp-btn-transparent" href="/account" onClick={() => aapiBeacon('click', { from: ANALYTICS_FROM_HOMEPAGE, op: ANALYTICS_SECTION_SUBSCRIPTIONS_DATA, section: 'auto-promo-newsletter' })}>
            <A18n rsx="homepage.focusonorautopromo.newsletter.m4" />
          </a>
        )}
      </div>
    </div>
  );
};

export default Newsletter;
